import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (




        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="contact"
        >

            {/* Start Contact Section ======= */}

            <section id="contact" class="contact">

<div class="container" data-aos="fade-up">

    <header class="section-header">
        <h2>Contact</h2>
        <p>Contact Us</p>
    </header>

    <div class="row gy-4">

        <div class="col-lg-12">

            <div class="row gy-4">
                <div class="col-md-6">
                    <div class="info-box">
                        <i class="bi bi-geo-alt"></i>
                        <h3>Address</h3>
                        <p>Carlsbad<br/> California USA <br/><br/></p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-box">
                        <i class="bi bi-chat-text"></i>

                        <h3>Text Us</h3>
                        <p>
                            <a class="whatsapp" href="https://wa.me/+17604814120/?text=Hello, Please ">
                                <i class="bi bi-whatsapp"></i> WhatsApp
                            </a>
                        </p>
                        <p>
                            <a href="tel:+17604814120 "><i class="bi-telephone"></i>  +1 (760) 481-4120 </a>
                        </p>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-box">
                        <i class="bi bi-envelope"></i>
                        <h3>Contact Us</h3>
                        <p><a href="/#contactus">Contact Form</a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-box">
                        <i class="bi bi-clock"></i>
                        <h3>Open Hours</h3>
                        <p>Monday - Friday<br />9 a.m. to 6 p.m</p>
                    </div>
                </div>
            </div>

        </div>



    </div>

</div>



</section>


            {/* End Contact Section ======= */}

        </motion.div>

    );
}

export default Home;
