import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import About from './components/About';
import Contact from './components/Contact';
import Contactus from './components/Contactus';
import FAQ from './components/FAQ';
import Hero from './components/Hero';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Pricing from './components/Pricing';
import Privacy from './components/Privacy';
import Services from './components/Services';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/hero" element={<Hero />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
