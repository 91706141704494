import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import AOS from 'aos';
import 'aos/dist/aos.css';

function FAQ() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="faq"
        >

            {/* F.A.Q Section ======= --> */}

            
        <section id="FAQ" class="faq">

            <div class="container" data-aos="fade-up">

                <header class="section-header">
                    <h2>F.A.Q</h2>
                    <p>Frequently Asked Questions</p>
                </header>

                <div class="row">
                    <div class="col-lg-6">

                        <div class="accordion accordion-flush" id="faqlist1">

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-2">
                                        How much does a new website cost?
                                    </button>
                                </h2>
                                <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div class="accordion-body">
                                        There’s not one single answer to this question. A website design is quoted based
                                        on the needs of each individual project. Every website is unique and requires
                                        different components; we design and develop custom websites specifically for
                                        your small business.
                                        We’ll ask a lot of questions, assess your needs, and give you a quote based on
                                        that assessment. Most of our sites run in the $3,000 – $5,000 range, but can be
                                        more or less depending on needs.
                                    </div>
                                </div>
                            </div>




                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-1">
                                        How long will it take to get a new website?
                                    </button>
                                </h2>
                                <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div class="accordion-body">
                                        On average, we shoot for a six to eight week turnaround, but the pace of any
                                        project is set by each client. How much input you can provide during the initial
                                        stages, your availability with feedback, how soon the content is ready – all
                                        this affects the
                                        speed of completion. The functionality needs may also play a role – more complex
                                        sites will take more time to develop.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-3">
                                        Why <b>Web</b>Wonders & <b>Carto</b>Craft?
                                    </button>
                                </h2>
                                <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div class="accordion-body">
                                        Discover <b>Web</b>Wonders & <b>Carto</b>Craft, your gateway to transformative
                                        web design and innovative mapping solutions. Our skilled team seamlessly blends
                                        artistic creativity with cutting-edge technology to construct visually
                                        captivating websites that engage and convert. Beyond web design, we excel in
                                        dynamic map integration, leveraging geospatial insights to empower your digital
                                        presence. Whether you're a business aiming to dominate online markets or an
                                        organization seeking insightful mapping solutions, we tailor strategies that
                                        drive results. Partner with us to unlock new digital horizons and lead with
                                        innovation.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6">

                        <div class="accordion accordion-flush" id="faqlist2">

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-1">
                                        What if I need help on my site down the road?
                                    </button>
                                </h2>
                                <div id="faq2-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div class="accordion-body">
                                        We are only an email away! We’re here to help you as much or as little as you
                                        need, and we won’t disappear once the site is launched. We’ve been doing this
                                        for many years, so we aren’t going anywhere anytime soon.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-2">
                                        When do I pay?
                                    </button>
                                </h2>
                                <div id="faq2-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div class="accordion-body">
                                        For most projects, equal payments are made at the start, midway, and the end of
                                        the project, but we can work with you to set up a schedule that meets your
                                        needs. We understand that this is a big investment and want to help you budget
                                        for the expense in
                                        whatever way possible.<br /> We accept checks, PayPal, and all major credit cards.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq2-content-3">
                                        Will my website be mobile-friendly?
                                    </button>
                                </h2>
                                <div id="faq2-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div class="accordion-body">
                                        Absolutely! Having a mobile-friendly website is more important than ever! We
                                        work hard to ensure your website looks great on a variety of devices.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </section>

            {/* End F.A.Q Section ======= */}


        </motion.div>

    );
}

export default FAQ;
