import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';


import pricingFree from '../assets/img/pricing-free.png';
import pricingStarter from '../assets/img/pricing-starter.png';
import pricingBusiness from '../assets/img/pricing-business.png';
import pricingUltimate from '../assets/img/pricing-ultimate.png';



function Home() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (




        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="pricing"
        >

            {/* Start Pricing Section ======= */}
            <section id="pricing" class="pricing">

<div class="container" data-aos="fade-up">

    <header class="section-header">
        <h2>Pricing</h2>
        <p>Check our Pricing</p>
    </header>

    <div class="row gy-4" data-aos="fade-left">

    <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
    <div class="box">
                <h3 >Free Quote</h3>
                <div class="price"><sup>$</sup>0<span> / mo</span></div>
                <img src={pricingFree} class="img-fluid" alt="" />
                <ul>
                    <li>Web site app quote</li>
                    <li>Revisions</li>
                    <li class="na">Installation</li>
                    <li class="na">Database design</li>
                    <li class="na">Web Design</li>
                </ul>
                <a href="/#contactus" class="btn-buy">Contact Us</a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div class="box">
                <span class="featured">Featured</span>
                <h3 >Software Installation</h3>
                <div class="price"><sup>$</sup>150<span>/Domain</span></div>
                <img src={pricingStarter} class="img-fluid" alt="" />
                <ul>
                    <li>Software Upload</li>
                    <li>Installation</li>
                    <li>Database setup + migr</li>
                    <li class="na">Upgrades</li>
                    <li class="na">Bug fixes</li>
                </ul>
                <a href="/#contactus" class="btn-buy">Contact Us</a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div class="box">
                <h3 >Website/ app Creation</h3>
                <div class="price">From <sup>$</sup>199<span> +</span></div>
                <img src={pricingBusiness} class="img-fluid" alt="" />
                <ul>
                    <li>Home page</li>
                    <li>3 pages</li>
                    <li>Contact form</li>
                    <li>10 revisions</li>
                    <li>Installation</li>
                </ul>
                <a href="/#contactus" class="btn-buy">Contact Us</a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div class="box">
                <h3 >Full Stack service</h3>
                <div class="price"><sup>$</sup>100<span> / h</span></div>
                <img src={pricingUltimate} class="img-fluid" alt="" />
                <ul>
                    <li>Website/ app Creation</li>
                    <li>Hosting/Cloud/ installation</li>
                    <li>Unlimited upgrades</li>
                    <li>Unlimited updates</li>
                    <li>Unlimited revisions</li>
                </ul>
                <a href="/#contactus" class="btn-buy">Contact Us</a>
            </div>
        </div>

    </div>

</div>

</section>


            {/* End Pricing Section ======= */}


        </motion.div>

    );
}

export default Home;
