import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from '../assets/img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../assets/css/style.css';

function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 50);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);  

    // Toggle menu open/close
    const toggleMenu = () => setMenuOpen(!menuOpen);

    // Close menu when item is clicked
    const closeMenu = () => setMenuOpen(false);

    return (
        <motion.nav
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            className={`navbar ${scrolled ? 'scrolled' : ''}`}
        >
             <section id="header">
            <header id="header" className={`header fixed-top ${scrolled ? 'header-scrolled' : ''}`}>
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <a href="#/" className="logo d-flex align-items-center">
                        <img src={Logo} alt="" />
                        <span>Web & Carto</span>
                    </a>

                    <nav id="navbar" className={`navbar ${menuOpen ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className="nav-link" href="/#about" onClick={closeMenu}>About</a></li>
                            <li><a className="nav-link" href="/#services" onClick={closeMenu}>Services</a></li>
                            <li><a className="nav-link" href="/#pricing" onClick={closeMenu}>Pricing</a></li>
                            <li><a className="nav-link" href="/#contactus" onClick={closeMenu}>Contact Us</a></li>
                            <li>
                                <a className="getstarted scrollto" href="/#contactus" onClick={closeMenu}>Get Started</a>
                            </li>
                        </ul>
                        <i className={`bi ${menuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMenu}></i>
                    </nav>
                </div>
            </header>
            </section>
        </motion.nav>
    );
}

export default Navbar;
