import React from 'react';
import 'aos/dist/aos.css';
import Logo from '../assets/img/logo.png';

function Footer() {

    const date = new Date();
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });

    return (


        <footer class="footer">

            <div class="footer-newsletter">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center">
                            <h4>Solution</h4>
                            <p>Ready to embark on your digital journey? Let's bring your vision to life. Reach out to us today for a free consultation.</p>
                        </div>
                        <div class="col-lg-12 text-center">
                            <a class="getstarted scrollto" href="/#contactus">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>



            <div class="footer-top">
                <div class="container">
                    <div class="row gy-4">
                        <div class="col-lg-5 col-md-12 footer-info">
                            <a href="index.html" class="logo d-flex align-items-center">
                                <img src={Logo} alt="" />
                                <span>Web and Carto</span>
                            </a>

                            Don't be afraid! We'll walk you through the entire process, hold your hand as much as you need, and help you through all the technical aspects that may seem scary. We work best with clients who leave the heavy lifting to us.

                            <div class="social-links mt-3">
                                <a href="https://twitter.com/tn76com" class="twitter">
                                    <i class="bi bi-twitter"></i></a>
                                <a href="https://www.linkedin.com/in/mohameditounsi/" class="linkedin">
                                    <i class="bi bi-linkedin bx bxl-linkedin"></i></a>

                                <a class="skype" href="skype:tounsi.med.ca">
                                    <i class="bi bi-skype"></i>
                                </a>

                                <a class="github" href="https://github.com/tounsils">
                                    <i class="bi bi-github"></i>
                                </a>

                                <a class="whatsapp" href="https://wa.me/+17604814120/?text=Hello, Please ">
                                    <i class="bi bi-whatsapp"></i>
                                </a>




                            </div>
                        </div>

                        <div class="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bi bi-chevron-right"></i> <a href="#/">Home</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#about">About us</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Services</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#/">Terms of service</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#privacy">Privacy policy</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#/">Documentation</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#FAQ">F.A.Q</a></li>
                            </ul>
                        </div>

                        <div class="col-lg-2 col-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Web Design</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Web Development</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Product Management</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Marketing</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/#services">Graphic Design</a></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            Carlsbad California USA
                            <br /><strong>Phone:</strong> +1 (760) 481-4120
                            <br /><strong>Email:</strong> <a href="mailto:tounsils@gmail.com?subject=Contacting through tn76.com">tounsils@gmail.com</a>

                            <div class="social-links mt-3">
                                <a href="https://twitter.com/tn76com" class="twitter">
                                    <i class="bi bi-twitter"></i></a>
                                <a href="https://www.linkedin.com/in/mohameditounsi/" class="linkedin">
                                    <i class="bi bi-linkedin bx bxl-linkedin"></i></a>

                                <a class="skype" href="skype:tounsi.med.ca">
                                    <i class="bi bi-skype"></i>
                                </a>

                                <a class="github" href="https://github.com/tounsils">
                                    <i class="bi bi-github"></i>
                                </a>

                                <a class="whatsapp" href="https://wa.me/+17604814120/?text=Hello, Please ">
                                    <i class="bi bi-whatsapp"></i>
                                </a>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="container">
                <div class="copyright">
                    &copy; 2011-{new Date().getFullYear()} Copyright. All Rights Reserved
                    <br /><span>Web and Carto tn76.com</span>
                </div>
                <div class="credits">
                    Made with <i className="fas fa-heart" style={{ color: 'red' }}></i> by Web & Carto using React <i className="ri-reactjs-line"></i>
                    <br />
                    Version 1.5
                    <br />

                    <a href="https://stackoverflow.com/users/10537019/mohamed-tounsi?tab=profile" class="sb-stackoverflow">
                        Mohamed I Tounsi (Stack-Overflow)</a>
                    <br />
                    <a href="https://github.com/tounsils">
                        <span class="bi bi-github"></span> tounsils</a>
                    <br />
                    Last update {monthYear}


                </div>
            </div>




        </footer>



    );
}

export default Footer;
