import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

import About from './About';
import Contactus from './Contactus';
import Hero from './Hero';
import Pricing from './Pricing';
import Services from './Services';


function Home() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (




        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="home-page"
        >

            {/* Hero Section ======= */}

            <Hero />


            {/* End Hero ======= */}

            {/* About Section ======= */}


            <About />


            {/* End About Section ======= */}


            {/* Services Section ======= */}
            <Services />
            {/* End Services Section ======= */}

            {/* Start Pricing Section ======= */}
            <Pricing />


            {/* End Pricing Section ======= */}

            {/* Start Contact Section ======= */}

            <Contactus />


            {/* End Contact Section ======= */}

        </motion.div>

    );
}

export default Home;
