import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

import aboutImg from '../assets/img/about.jpg';

function About() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="about"
        >

            {/* About Section ======= */}

            <section id="about" class="about">

            <div class="container" data-aos="fade-up">

                <header class="section-header">
                    <h2>Who We Are</h2>
                    <p>Team of talented experts</p>
                </header>
                
                <div class="row gy-2 content">

                    <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                        <div class="content">
        

                                <h2>Team of talented experts providing insights through geospatial data visualization</h2>
                            <p>
                                Our expertise spans from designing responsive websites that captivate audiences to integrating interactive maps that provide insights through geospatial data visualization.
                                </p>  <div class="text-center text-lg-start">
                                <a href="/#contactus" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>Contact us</span>
                                    <i class="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>

    

                    </div>

                <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                        <img src={aboutImg} class="img-fluid" alt="" />
                    </div>

                </div>
            </div>


            </section>
            {/* End About Section ======= */}


        </motion.div>

    );
}

export default About;
