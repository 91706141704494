import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Services() {

    useEffect(() => {
        AOS.init({ duration: 600 });
    }, []);

    return (


        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="services"
        >

     


            {/* Services Section ======= */}
            <section id="services" class="services">


            <div class="container" data-aos="fade-up">

                <header class="section-header">
                    <h2>Services</h2>
                    <p>Website and Web Application Development</p>
                </header>
                
                <div class="row gy-4">

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div class="service-box blue">
                            <i class="ri-layout-masonry-line icon"></i>
                            <h3>Responsive Design</h3>
                            <p>

                                Client-focused, customer-centric solutions.</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div class="service-box orange">
                            <i class="ri-coins-line icon"></i>
                            <h3>E-Commerce</h3>
                            <p>Start selling right away!</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                        <div class="service-box green">
                            <i class="ri-user-follow-line icon"></i>
                            <h3>User Experience</h3>
                            <p>Your customer will love it!</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                        <div class="service-box red">
                            <i class="ri-rocket-line icon"></i>
                            <h3>Fast Page Loading</h3>
                            <p>We don’t make our customers wait.</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                        <div class="service-box purple">
                            <i class="ri-wheelchair-line icon"></i>
                            <h3>Aim for accessibility.</h3>
                            <p>Contrast and background effectiveness, all text responsive</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                        <div class="service-box pink">
                            <i class="ri-device-line icon"></i>
                            <h3>Mobile solutions</h3>
                            <p>Added value to mobile solutions for society, industries, organizations, and administrative departments.</p>
                            <a href="#/" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a>
                        </div>
                    </div>

                </div>

            </div>

            </section>
            {/* End Services Section ======= */}


        </motion.div>

    );
}

export default Services;
